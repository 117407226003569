.chart {
	margin-top: 7rem;
	margin-right: 5rem;
	/* position: absolute; */
	z-index: -500;
}
.apexcharts-xaxis-label {
	margin-left: 300px;
}
.count-color {
	color: rgb(33, 20, 76) !important;
}
.datePicker-input {
	padding: 0.5rem !important;
}
.datePicker-input {
	border-radius: 5px;
}
.datePicker-input:focus-visible {
	border: 2px solid blue;
}
.datePoicker-parent {
	display: flex;
	justify-content: space-between;
}
.weekly {
	margin-left: 2rem;
	margin-top: 1rem;
}
.datePicker-child {
	position: relative;
	z-index: 100;
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
}
.second-datepicker {
	margin-left: 0.5rem;
	margin-right: 0.7rem;
}

.inner-body-date-picker {
	z-index: 100 !important;
}

.WeekyCardParent {
	/* left: 18%; */
	/* top: 20%; */
	margin-top: 10%;
	width: 100%;
	z-index: -5;
}

.signUp-names-bold {
	font-size: 20px;
	font-weight: 500;
}

@media screen and (max-width: 992px) {
	.chart {
		margin-right: rem;
		margin-left: 5rem;
	}
}

@media screen and (max-width: 1025px) {
	.second-datepicker {
		margin-left: 0.5rem;
		margin-right: 0.3rem;
	}
}

@media screen and (max-width: 870px) {
	.datePoicker-parent {
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
	}
	.datePicker-child {
		position: relative;
		z-index: 100;
		display: flex;
		flex-direction: column;
		margin-left: 2rem;
	}
	.second-datepicker {
		margin-left: 0rem;
		margin-top: 0.5rem;
	}
}

@media screen and (max-width: 330px) {
	.datePicker-child {
		position: relative;
		z-index: 100;
		display: flex;
		flex-direction: column;
		margin-left: 1rem;
	}
	.weekly {
		margin-left: 1rem;
	}
}
