.parent {
	width: 50%;
	margin: 7% auto;
}
.cardContent {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid gray;
}
.contained {
	display: flex;
	justify-content: flex-start;
	width: 50%;
	margin-top: 30px;
}

.input {
	margin: 20px;
}

.portalWrap {
	white-space: nowrap;
	font-size: 42px;
}

.loginbtn {
	display: flex !important;
}

.catLink {
	color: #000000;
	text-decoration: none;
	font-size: 1.4rem;
	/* margin-left: 18%; */
}
.catLink:hover {
	background: linear-gradient(213.34deg, #dcf788 25.02%, #ffffff 80.24%);
	border-radius: 0.5rem;
}
.activeDiv {
	color: #000000;
	text-decoration: none;
	/* margin-left: 18%; */
	background: linear-gradient(213.34deg, #dcf788 25.02%, #ffffff 80.24%);
	border-radius: 0.5rem;
	font-size: 1.4rem;
}

.loginParent {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.loginCard {
	min-width: 450px;
	height: 400px;
}

.loginHeading {
	display: flex;
	justify-content: center;
}

.adminLoginParent {
	display: flex;
	flex-direction: column;
}

.email {
	margin-bottom: 20px;
}
.iconMargin {
	margin-bottom: 5px;
	margin-top: 2px;
}
.iconButton {
	text-transform: capitalize !important;
	padding: 2px !important;
}

.addCategoryBtn {
	text-transform: capitalize !important;
	padding: 10px 15px !important;
	height: fit-content;
	background: #dcf788 !important;
	color: black !important;
	font-size: 1.2rem !important;
}

.btnParent {
	text-align: center;
	width: 100%;
	margin-top: 50px;
}
.selectStatuses {
	width: 130px;
	height: 40px;
	margin-left: 10px;
}
body {
	background-color: #fbfbfb;
}
.innerBtn {
	padding: 20px;
}

.sideBarMobile {
	width: 285px !important;
}

.sidebarBackground {
	background: linear-gradient(213.34deg, #dcf788 25.02%, #ffffff 80.24%);
	z-index: 100;
}
.nav-index {
	z-index: -1;
}

.logo-margin {
	text-align: center;
	border-bottom: 1px solid gray;
}

.linksParent {
	/* margin-left: 30px; */
	margin-top: 20px;
	display: flex;
	flex-direction: column;
}

.icon-color {
	color: white;
}

.menu-mobile {
	display: flex;
	flex-direction: row-reverse;
	margin-right: 90px;
}

.profile {
	margin-right: 20px;
}

.nav-height {
	height: 73px;
}

.home {
	margin-left: 20%;
}

.sellerInputParent {
	display: flex;
	justify-content: flex-end;
}
.card-content {
	padding: 20px 56px 60px 58px;
}

.cardParent {
	left: 22%;
	position: absolute;
	top: 20%;
	width: 73%;
	z-index: -5;
}

.loaderCenter {
	position: absolute;
	align-content: center;
	width: 100%;
	height: 100vh;
	z-index: 100;
	align-items: center;
}

/* Sidebar */
.sidebar {
	position: fixed;
	overflow-y: scroll;
	top: 0;
	bottom: 0;
	left: 0; /* Height of navbar */
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
	width: 18%;
	z-index: 600;
}
.menu-color {
	color: gray;
}
.menu-border {
	color: white;
}

.parent-cat {
	display: flex;
	justify-content: flex-end;
	border-bottom: 1px solid gray;
	margin-bottom: 29px;
	padding: 10px 0px;
}

.add-cat {
	display: flex;
	justify-content: flex-end;
	/* background: #dcf788 !important; */
	color: black !important;
}

.sidebar .active {
	border-radius: 5px;
	box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
	position: relative;
	top: 0;
	height: calc(100vh - 48px);
	padding-top: 0.5rem;
	overflow-x: hidden;
	overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.dropdown-menu-dark {
	background: linear-gradient(
		213.34deg,
		#091431 13.02%,
		#1e2d53 95.24%
	) !important;
}
option:hover {
	background-color: yellow;
}
.header-logo {
	width: 2.9rem;
	margin-right: 3rem;
}

@media screen and (max-width: 550px) {
	.parent {
		width: 100%;
		margin: 5% 3%;
		padding-right: 20px;
	}
	.cardParent {
		left: 0%;
		position: absolute;
		top: 10%;
		width: 98%;
	}
	.menu-mobile {
		display: flex;
		flex-direction: row-reverse;
		margin-right: 0px;
	}

	.toggler {
		display: flex !important;
	}
	.loginbtn {
		display: none !important;
	}

	.loginParent {
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}
	.loginCard {
		min-width: 300px;
		height: 370px;
	}
	.card-content {
		padding: 20px 15px 44px 14px;
	}
}

@media screen and (max-width: 992px) {
	.sidebar {
		width: 100%;
	}
	.linksParent {
		margin-left: 0px;
		margin-top: 20px;
		display: flex;
		flex-direction: column;
	}
	.home {
		display: none;
	}
	.cardParent {
		left: 5%;
		position: absolute;
		top: 10%;
		width: 88%;
	}
}
