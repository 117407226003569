.forgot-form {
	margin-bottom: 7rem;
}
.login-link {
	text-align: center;
}
.paragraph {
	font-size: 12px;
	color: blue;
}
.forgot-inner-form {
	width: 50%;
}
.forgot {
	background-color: rgb(182, 246, 7);
	color: var(--black);
	width: 30%;
	border: none;
	font-size: 18px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
}
.email-required {
	margin-top: -0.5rem;
}
@media screen and (max-width: 550px) {
	.forgot-inner-form {
		width: 100%;
	}
	.forgot {
		width: 50%;
		font-size: 15px;
	}
}
@media screen and (max-width: 475px) {
	.forgot-form {
		margin-bottom: 15rem;
	}
	.login-link {
		font-size: 16px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media screen and (max-width: 350px) {
	.forgot {
		width: 55%;
	}
}
.forgot:hover {
	background-color: rgb(199, 243, 78);
	color: var(--black);
}
.forgot:active {
	background-color: rgb(199, 243, 78) !important;
	color: var(--black) !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
