.air-button-next {
	background-color: rgb(207, 255, 76);
	color: var(--black);
	width: 10rem;
	font-size: 16px;
	font-weight: bold;
}

.air-button-next:hover {
	background-color: rgb(182, 246, 7);
	color: var(--black);
	font-weight: bold;
	width: 10rem;
	font-size: 16px;
}
@media screen and (max-width: 550px) {
	.air-button-next {
		margin-bottom: 5rem;
	}
}

@media screen and (max-width: 295px) {
	.air-button-next {
		margin-bottom: 9rem;
	}
}
