.seed-datePicker-input input {
	width: 100%;
}

.inner-seed-date-picker {
	width: 100%;
	padding: 8px;
	margin-top: 13px;
}

.react-datepicker-wrapper {
	width: 100%;
}
