.outlier-padding {
	padding: 0% 25%;
	height: 100%;
	overflow-y: scroll;
	margin-bottom: 10%;
}

.outlier-under-logo {
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 17px;
	margin-top: 5px;
}
.outlier-main-heading-mobile {
	font-size: 20px;
	text-align: center;
	text-decoration: none;
	color: var(--grey);
}
.outlier-main-heading {
	font-size: 2.5rem;
	text-align: center;
	text-decoration: none;
	color: var(--grey);
}
.outlier-placeholder-color ::placeholder {
	color: red;
}

.classification-field {
	width: 100%;
}

.outlier-parent {
	margin-top: 8rem;
}

.us-local {
	text-align: center;
	font-size: 1.7rem;
	color: var(--grey);
}

.image-width {
	width: 15%;
}

.search-parent-div {
	width: 60%;
}

@media screen and (max-width: 400px) {
	.outlier-under-logo-mobile {
		font-size: 1.3rem;
		font-weight: 500;
		text-align: center;
		margin-bottom: 17px;
		margin-top: 5px;
	}

	.image-width {
		width: 35% !important;
	}
}

@media screen and (max-width: 600px) {
	.outlier-parent {
		margin-top: 2rem;
	}
	.search-parent-div {
		width: 100% !important;
		margin-bottom: 5%;
	}
	.classification-field {
		width: 100%;
		margin-top: 20px;
	}

	.image-width {
		width: 10%;
	}
}

@media screen and (max-width: 768px) {
	.search-parent-div {
		width: 80%;
	}
	.us-local {
		text-align: center;
		font-size: 1.2rem;
		color: var(--grey);
	}
	.image-width {
		width: 50%;
	}
}
