.about-heading {
	font-weight: 600;
}

.about-padding {
	padding: 20px 192px;
}

.main-heading-font {
	font-size: 2rem;
}

.sub-heading-font {
	font-size: 1.5rem;
}

.sub-sub-heading-font {
	font-size: 1rem;
}

.middle-text {
	padding: 0px 80px 15px 80px;
}

@media screen and (max-width: 1400px) {
	.middle-text {
		padding: 0px 0px;
	}
}
@media screen and (max-width: 600px) {
	.about-padding {
		padding: 10px 40px;
	}

	.middle-text {
		padding: 0px;
	}
	.main-heading-font {
		font-size: 1.5rem;
	}

	.sub-heading-font {
		font-size: 1rem;
	}
}
