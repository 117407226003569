.seller-profile-page-container {
	height: 28vh;
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

.back-btn {
	border: 1px solid gray;
	border-radius: 5px;
}

.seller-profiles-link {
	font-size: 20px;
	font-weight: 600;
	position: absolute;
	margin-top: 20px;
	margin-left: 50px;
	color: black;
	cursor: pointer;
}

.seller-profiles-link-mobile {
	font-size: 20px;
	font-weight: 600;
	color: black;
	cursor: pointer;
}

.seller-link-color {
	color: var(--limegreen) !important;
	font-weight: 600;
}

.seller-profile-url-link {
	font-size: 60px;
}

.seller-profiles-parent-comp {
	opacity: 0.7;
	height: 86px;
}

@media screen and (max-width: 500px) {
	.seller-profiles-parent-comp {
		opacity: 0.7;
		height: 80px;
	}
	.seller-profile-url-link {
		font-size: 30px;
	}

	.back-btn {
		border: 1px solid gray;
		border-radius: 5px;
		font-size: small;
	}
	.seller-profile-page-container {
		height: 25vh;
	}
}
