.comment-section-height {
	height: 6.6rem;
    overflow-y: auto;
}
.button-commentSection {
	background-color: var(--white);
}
.button-commentSection:hover {
	background-color: var(--white);
}
.emoji-badge {
	background-color: transparent !important;
	margin-top: 0px;
	position: absolute;
	top: -9px;
	right: -16px;
	font-size: 20px;
}
.avatar {
	display: flex;
	align-items: center;
}
.all-stories-background {
	background-color: var(--yellow) !important;
	color: black !important;
	border: 1px solid var(--yellow);
}
.all-stories-background :hover {
	background-color: var(--yellow) !important;
	color: black !important;
	border: 1px solid var(--yellow);
}
.notify-btn {
	background-color: var(--limegreen) !important;
	color: var(--black);
	font-size: 14px;
}
.notify-btn-winner {
	background-color: #feffd0 !important;
	border: 2px solid #000;
	color: var(--black);
	font-size: 1.5rem;
	font-weight: 800;
	margin-right: 0.5rem;
}
.notify-btn:hover {
	background-color: rgb(199, 243, 78) !important;
}

.seller-balloon-btn {
	background-color: var(--limegreen) !important;
	color: var(--black);
	font-size: 13px;
}
.seller-balloon-btn:hover {
	background-color: rgb(199, 243, 78) !important;
	color: var(--black);
}

.already-clicked-parent {
	position: relative;
}

.btn-helpful-parent {
	width: fit-content;
}

.already-clicked {
	display: none;
	right: 0;
	opacity: 0;
}
.already-clicked-parent:hover .already-clicked {
	position: absolute;
	visibility: visible;
	display: block;
	opacity: 1;
	color: black;
	background-color: #fea9dd;
	cursor: pointer;
	width: 120px;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 600;
	display: block;
	padding: 4px;
	right: 0;
	text-align: center;
}

.textBlack {
	color: black;
}
.textBlue {
	color: #0d6efd;
}
.report-btn {
	background-color: none !important;
	font-size: 4px;
}
.wrapper-commentSection-container {
	background-color: #c5c1c1;
}
.space {
	color: black;
	background-color: white;
	height: 50%;
	padding: 10px;
}
.review_width {
	/* width: 128%; */
	display: flex;
	margin-left: 1rem;
}
.Awesome-dropdown-item {
	background: #ffeea7 !important;
	color: black;
	border: 1px solid #ffeea7;
}
.Happy-dropdown-item {
	background: var(--limegreen) !important;
	color: black;
	border: 1px solid var(--limegreen);
}
.Satisfied-dropdown-item {
	background: #74d1e5 !important;
	color: black;
	border: 1px solid #74d1e5;
}
.Disappointe-dropdown-item {
	background: #f29fde !important;
	color: black;
	border: 1px solid #f29fde;
}
.Frustrate-dropdown-item {
	background: #2a94c6 !important;
	color: black;
	border: 1px solid #2a94c6;
}
.Grr-dropdown-item {
	background: #eb8c8a !important;
	color: black;
	border: 1px solid #eb8c8a;
}

.Awesome-dropdown-item-border {
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px #ffeea7;
}
.Happy-dropdown-item-border {
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px var(--limegreen);
}
.Satisfied-dropdown-item-border {
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px #73d3e3;
}
.Disappointed-dropdown-item-border {
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px #f29fde;
}
.Frustrated-dropdown-item-border {
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px #84bee0;
}
.Grrr-dropdown-item-border {
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px #eb8c8a;
}

.Awesome-dropdown-item-border-new {
	border: 1px solid black !important;
	box-shadow: inset 0px 3px 2px 3px #ffeea7;
}
.Happy-dropdown-item-border-new {
	border: 1px solid black !important;
	box-shadow: inset 0px 3px 2px 3px var(--limegreen);
}
.Satisfied-dropdown-item-border-new {
	border: 1px solid black !important;
	box-shadow: inset 0px 3px 2px 3px #73d3e3;
}
.Disappointed-dropdown-item-border-new {
	border: 1px solid black !important;
	box-shadow: inset 0px 3px 2px 3px #f29fde;
}
.Frustrated-dropdown-item-border-new {
	border: 1px solid black !important;
	box-shadow: inset 0px 3px 2px 3px #84bee0;
}
.Grrr-dropdown-item-border-new {
	border: 1px solid black !important;
	box-shadow: inset 0px 3px 2px 3px #eb8c8a;
}
/* 
.max-width-dropdown-item-border {
	border: 1px solid black;
	box-shadow: inset 0px 3px 2px 3px #f29fde;
} */

.user-avatar {
	margin-left: 0px;
}

.edit-btn-user {
	margin-left: 20px;
}

.web-dropdown {
	background: #5555552b !important;
	color: var(--black) !important;
	border: 1px solid #5555552b;
}
.web-dropdown:focus {
	background: #5555552b !important;
	color: var(--black) !important;
	border: 1px solid #5555552b;
}
.web-dropdown:active {
	background: #5555552b !important;
	color: var(--black) !important;
}
.web-dropdown:hover {
	background: #5555552b !important;
	color: var(--black) !important;
}
.btn.show {
	background: #5555552b !important;
	color: var(--black) !important;
}
.web-dropdown-items:hover {
	background: #5555552b !important;
	color: var(--black);
}
.web-dropdown-items:active {
	background: #5555552b !important;
	color: var(--black);
}

.web-dropdown-items {
	font-weight: 600 !important;
	/* color: #5555552b !important; */
}
.all-stories-dropdown-item {
	background: #c1c1c1 !important;
	color: black !important;	
	/* font-weight: 700; */
}
.tester-image {
	width: 60px;
	height: 60px;
	border-radius: 15px;
}
.dropdown-menu {
	padding: 0 !important;
}

.p {
	background-color: white;
	border-radius: 0.5rem;
	padding-top: 1rem;
}
.image_div {
	margin-bottom: -1rem;
}
.image_shadow {
	filter: drop-shadow(rgb(65, 65, 65) 2px 2px 1px);
	width: 4rem;
}
.year-left {
	margin-left: 4px;
}
.image_head {
	margin-left: 2rem;
}
.badge {
	background-color: var(--blue) !important;
	margin-top: 0px;
	margin-left: -9px;
	position: absolute;
}
.date_text {
	margin-right: 1.4rem;
	margin-bottom: 10px;
}
.image_heading {
	font-weight: bold;
	font-size: 1.3rem;
	margin-bottom: 0 !important;
}
.text_area-comments {
	width: 93%;
}


/*.justify-content-xl-start {
	justify-content: flex-start !important;
}*/

.user-name {
	display: flex;
	align-items: center;
	margin-left: 0.7rem;
	margin-top: 1rem;
}
.orignal-text {
	display: flex;
	align-items: center;
	margin-left: 0.7rem;
	font-size: 0.8rem;
}
.report-badge {
	bottom: 29px;
	left: 44px;
}
.flag-icon {
	color: red;
	font-size: 1rem;
	padding-top: 3px;
}

.flag-icon-grey {
	font-size: 1rem;
	padding-top: 3px;
}
.nominate_button {
	text-align-last: right;
}
.toaster-container {
	margin-top: -1.5rem;
	margin-right: -0.5rem;
}
.nominateLoader {
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	align-items: center;
}
.love-air-container {
	display: flex;
}
.love-balloons {
	display: flex;
}
.profile-btn {
	margin-right: 0px;
}
.air-balloons {
	display: flex;
}
.web-dropdown-parent {
	background: #5555552b !important;
	color: var(--black) !important;
}

.avatar-margin-left {
	margin-left: 3px;
}

@media screen and (max-width: 578px) {
	.avatar-margin-left {
		margin-left: 12px;
	}
	.image_shadow {
		filter: drop-shadow(rgb(65, 65, 65) 2px 2px 1px);
		width: 4rem;
		margin-left: -1rem;
	}

	.image_head {
		margin-left: 1.5rem;
	}

	.space {
		font-style: italic;
	}
	.tester-image {
		width: 48px !important;
		height: 48px !important;
		border-radius: 15px !important;
		margin-top: 11px;
	}
}

@media screen and (max-width: 768px) {
	.review_width {
		width: 95%;
		display: flex;
		margin-left: 0rem;
	}
	.user-avatar {
		margin-left: 22px;
	}
	.tester-image {
		width: 48px !important;
		height: 48px !important;
		border-radius: 15px !important;
		margin-top: 11px;
	}
	.image_heading {
		font-weight: bold;
		margin-top: 0.7rem;
		font-size: 1rem;
	}
	.date_text {
		font-size: 0.9rem;
		margin-right: 0.7rem;
	}
	.image_shadow {
		filter: drop-shadow(rgb(65, 65, 65) 2px 2px 1px);
		width: 4rem;
		margin-left: -2.5rem;
	}
}

@media screen and (max-width: 992px) {
	.nominate_button {
		text-align-last: end;
	}
	.profile-btn {
		margin-right: 20px;
	}
	.air-balloons {
		flex-direction: column;
	}
	/* .love-air-container {
		display: block;
	} */
	.love-balloons {
		flex-direction: column;
	}
	.text_area-comments {
		width: 89%;
	}
}

@media screen and (max-width: 1200px) {
	/*.justify-content-xl-start {
		width: 45%;
        justify-content: flex-start !important;
	}*/
	.orignal-text-mobile {
		display: flex !important;
		align-items: center;
		margin-left: 0.7rem;
		font-size: 0.8rem;
	}
	.orignal-text {
		display: none;
	}
}
