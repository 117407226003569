.animation {
	position: relative;
	animation-duration: 2s;
	animation-delay: 0.3s;
	animation-name: bounce;
	filter: drop-shadow(rgb(102, 102, 102) 5px 5px 5px);
}

.parent-review-width {
	width: 75%;
}
.love-seller-cursor {
	cursor: pointer;
}
.love-review-title {
	font-size: 1.2rem;
	font-weight: 600;
}
.animation1 {
	position: relative;
	animation-duration: 2s;
	animation-delay: 0.3s;
	animation-name: bounce;
	filter: drop-shadow(rgb(102, 102, 102) 5px 5px 5px);
}

.animation2 {
	position: relative;
	animation-duration: 2s;
	animation-delay: 0.3s;
	animation-name: bounce;
	filter: drop-shadow(rgb(102, 102, 102) 5px 5px 5px);
}

@keyframes bounce {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(-40px);
	}
	50% {
		transform: translateY(5px);
	}
	70% {
		transform: translateY(-25px);
	}
	80% {
		transform: translateY(2px);
	}
	90% {
		transform: translateY(-15px);
	}
	100% {
		transform: translateY(0);
	}
}
.love_shadow {
	text-decoration-line: underline;
}
.love_shadow1 {
	text-decoration-line: underline;
}
.love_shadow2 {
	text-decoration-line: underline;
}
h4 {
	font-size: 1rem;
	font-weight: 600;
}

@media screen and (max-width: 768px) {
	.parent-review-width {
		width: 100%;
	}
	.love-review-title {
		font-size: 16px;
		font-weight: 400;
	}
}
