.close-icon-end {
	text-align: start;
	cursor: pointer;
}
.animated-parent {
	display: none;
}
.content h1 {
	padding: 75px 0 30px 0;
	text-align: center;
	font-size: 30px;
	line-height: 30px;
}
.content .buttons {
	max-width: 800px;
	margin: 0 auto;
	padding: 0;
	text-align: center;
}
.content .buttons .button {
	display: inline-block;
	text-align: center;
	padding: 10px 15px;
	margin: 10px;
	background-color: #efefef;
	font-size: 18px;
	border-radius: 3px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
	cursor: pointer;
}
.content .buttons .button:hover {
	color: white;
	background: #009bd5;
}

.modal-actives {
	overflow: hidden;
}

.modal-container-animated {
	position: fixed;
	display: table;
	height: 100vh;
	width: 100%;
	top: 0;
	left: 0;
	transform: scale(0);
	z-index: 1000;
}
.one {
	transform: scaleY(0.01) scaleX(0);
	animation: unfoldIn 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal-out {
	transform: scale(1);
	animation: unfoldOut 1s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal-container-animated .one .modal-background-animated .modal-animated {
	transform: scale(0);
	animation: zoomIn 0.5s 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.modal-container-animated
	.one
	.modal-out
	.modal-background-animated
	.modal-animated {
	animation: zoomOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.animated-modal-bg {
	background-color: white;
	height: 100vh;
	width: 100vw;
	position: fixed;
	z-index: 100;
	top: 0;
}

.modal-parents {
	width: 62%;
	height: fit-content;
}

.modal-container-animated .modal-background-animated {
	display: table-cell;
	background: #000 !important;
	text-align: center;
	vertical-align: middle;
}

.modal-container-animated .modal-background-animated-before {
	display: table-cell;
	background: white !important;
	text-align: center;
	vertical-align: middle;
}

.modal-container-animated .modal-background-animated .modal-animated {
	background: white;
	padding: 15px;
	display: inline-block;
	border-radius: 3px;
	font-weight: 300;
	position: relative;
}
.modal-container-animated .modal-background-animated .modal-animated h2 {
	font-size: 25px;
	line-height: 25px;
	margin-bottom: 15px;
}
.modal-container-animated .modal-background-animated .modal-animated p {
	font-size: 18px;
	line-height: 22px;
}

@keyframes unfoldIn {
	0% {
		transform: scaleY(0.005) scaleX(0);
	}
	50% {
		transform: scaleY(0.005) scaleX(1);
	}
	100% {
		transform: scaleY(1) scaleX(1);
	}
}
@keyframes unfoldOut {
	0% {
		transform: scaleY(1) scaleX(1);
	}
	50% {
		transform: scaleY(0.005) scaleX(1);
	}
	100% {
		transform: scaleY(0.005) scaleX(0);
	}
}
@keyframes zoomIn {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes zoomOut {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}

@media screen and (max-width: 900px) {
	.modal-parents {
		width: 70%;
		top: 100px;
	}
}

@media screen and (max-width: 768px) {
	.modal-parents {
		width: 97%;
		top: 100px;
	}
	.modal-container-animated .modal-background-animated {
		display: table-cell;
		background: #000;
		text-align: center;
		vertical-align: top;
	}

	.modal-container-animated .modal-background-animated .modal-animated {
		background: white;
		padding: 7px;
		display: inline-block;
		border-radius: 3px;
		font-weight: 300;
		position: relative;
	}
	.modal-container-animated .modal-background-animated .modal-animated {
		background: white;
		padding: 15px;
		display: inline-block;
		border-radius: 3px;
		font-weight: 300;
		position: relative;
		margin-top: 33%;
	}
}
