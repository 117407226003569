.wrapper-seller-container {
	margin-bottom: 10px;
	text-align: "webkit-center";
}
.img-width {
	width: 50%;
}
.writer-close-icons {
	position: absolute;
	top: 25px;
	right: 30px;
}
.view-prize-left {
	margin-left: 3px;
}

.category-pointers {
	cursor: pointer;
}
.modal-ul-links {
	z-index: 10;
	position: relative;
}
.dialog-title {
	font-size: 20px !important;
	font-weight: 600 !important;
	text-align: center;
	margin-bottom: 20px !important;
}

.home-inial-text {
	width: 50%;
}
.balloonStory {
	font-style: italic;
}
.auto-seller-parent {
	margin-bottom: 10px;
}
.home-text {
	color: #525252;
	margin-top: -2rem;
	font-style: italic;
	font-size: 1.5rem;
	margin-bottom: 3rem;
}
.latest-seller-header {
	text-align: center;
    font-size: 25px;
    margin-bottom: 10px;
}
.balloon-click-color {
	color: #dcf788
}
.cat-margin {
	margin-right: 20px;
}
.seller-margin {
	margin-right: 10px;
}
.date-font {
	font-size: 20px;
}
.recent-modal {
	height: fit-content;
	/* overflow-y: scroll; */
	color: var(--limegreen);
}
.pagination-white {
	color: white !important;
}
.customModal {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 1px;
	width: 25%;
	height: 60%;
	margin-top: -24%;
	margin-left: 3%;
	z-index: 0 !important;
	/* background-color: #f0f0f0; */
	/* Add shadow properties */
	box-shadow: 0.3px 0.5px 1px #f9efef;
}

.cross {
	display: flex;
	justify-content: flex-end;
}

.heading {
	display: flex;
	justify-content: center;
}
.notice {
	font-family: "cursive";
	font-size: 24px;
}

.hover-overlay {
	transition: opacity 0.3s ease-in-out !important;
}
  
.hover-overlay:hover {
	opacity: 1 !important;
}

@media screen and (max-width: 425px) {
	.home-text {
		color: #525252;
		margin-top: 0rem;
		font-size: 14px;
		font-style: italic;
	}
	.img-width {
		width: 47%;
	}
	.mobile-spacing {
		margin-top: 1rem;
	}
}

@media screen and (max-width: 542px) {
	.home-text {
		color: #525252;
		margin-top: 0rem;
		font-size: 16px;
		font-style: italic;
		margin-top: -10px;
	}
	.img-width {
		width: 75%;
	}
}

@media screen and (max-width: 768px) {
	.home-inial-text {
		width: 75%;
	}
	.home-text {
		color: #525252;
		margin-top: 0rem;
		font-style: italic;
		margin-top: -10px;
	}
	.auto-seller-parent {
		margin-bottom: 66px;
	}
}

@media screen and (max-width: 1000px) {
	.customModal {
		display: flex;
		position: relative;
		z-index: 1000;
		align-items: center;
		justify-content: flex-start;
		padding: 20px;
		width: 96%;
		height: 60%;
		margin-top: -23%;
		z-index: 0 !important;
	}
}

@media screen and (max-width: 600px) {
	.customModal {
		display: flex;
		position: relative;
		z-index: 1000;
		align-items: center;
		justify-content: flex-start;
		padding: 20px;
		width: 94%;
		height: 60%;
		margin-top: -70%;
		z-index: 0 !important;
	}
}
