.signup-container {
	margin-bottom: 7rem;
}
.eye-icon-signUp {
	position: absolute;
	right: 20px;
	top: 10px;
	cursor: pointer;
}
.signup-form-width {
	width: 50%;
}
.signup-form-width-inputs {
	width: 75%;
}
.font-password {
	font-size: 13px;
}
.signup-btn {
	background-color: rgb(207, 255, 76);
	color: var(--black);
	width: 30%;
	border: none;
	font-size: 16px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
	padding-top: 6px;
	padding-bottom: 6px;
	font-weight: bold;
}
.recaptchParent {
	display: flex;
	width: 100%;
	justify-content: center;
}
.signup-btn:hover {
	background-color: rgb(182, 246, 7);
}
.signup-btn:active {
	background-color: rgb(182, 246, 7) !important;
	color: var(--black) !important;
}
.signup-link {
	text-align: center;
}
.paragraph {
	font-size: 12px;
	color: blue;
}
.error-message {
	color: red;
}

.loaderCenter {
	position: absolute;
	align-content: center;
	width: 100%;
	height: 100%;
	z-index: 100;
	align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

@media screen and (max-width: 450px) {
	.signup-container {
		margin-bottom: 14rem;
	}
	.signup-link {
		font-size: 14px;
		padding-left: 10px;
		padding-right: 10px;
	}
	.signup-btn {
		width: 40%;
		font-size: 15px;
	}
}

@media screen and (max-width: 600px) {
	.signup-form-width {
		width: 100%;
	}
	.signup-form-width-inputs {
		width: 90%;
	}
	.signup-container {
		margin-bottom: 10rem;
		width: 100%;
	}
	.signup-link {
		font-size: 14px;
		padding-left: 10px;
		padding-right: 10px;
	}
}
