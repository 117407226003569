.button-next {
	background-color: rgb(207, 255, 76);
	color: black;
	width: 10rem;
	font-size: 16px;
	font-weight: bold;
}
.button-next:hover {
	color: var(--black);
	background-color: rgb(182, 246, 7);
}

.tell-story-input {
	width: 75%;
	height: 130px;
}

@media screen and (max-width: 600px) {
	.tell-story-input {
		width: 95%;
		height: 90px;
	}
}
