.about-wrapper-container {
	margin-bottom: 20px;
}
.unorder_list {
	margin-left: 2rem;
}
@media screen and (max-width: 550px) {
	.unorder_list {
		margin-left: 0.9rem;
	}
}
