.button-sellers {
	background-color: #ffd50a;
	font-weight: 600;
	color: var(--black);
	height: 2.2rem;
	border-color: #a5a5a5;
	width: 70px;
}
.button-sellers:disabled {
	background-color: #ffd50a !important;
}
.buttons-container {
	margin-left: -3rem;
	display: flex;
	justify-content: flex-start;
}
.Frustrated-margin {
	margin-left: 10px;
}
.sup {
	top: -1.5rem;
	font-size: 18px;
	margin-left: 1rem;
}
.button-sellers:hover {
	background-color: var(--grey);
	color: var(--white);
}
.button-sellers:active {
	background-color: var(--grey) !important;
	color: var(--white) !important;
}
.love-button {
	margin-top: -2px;
}
.air-button {
	margin-top: -2px;
}
.tell-button {
	margin-top: -2px;
	margin-left: -3px;
}
.Happy {
	position: relative;
	margin: -4.5rem;
	filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
}
.Awesome {
	position: relative;
	margin: -3.5rem;
	filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
}
.Satisfied {
	position: relative;
	margin: -2.5rem;
	filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
}
.Frustrated {
	position: relative;
	margin: -1.5rem;
	filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
}
.Grr {
	position: relative;
	margin: 0.5rem;
	filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
}
.Disappointment {
	position: relative;
	margin: 2.5rem;
	filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
}

.font-seller-profile {
	font-size: 20px;
}

.reviews-buttons {
	margin-left: 1%;
}

.review-balloon-images {
	padding-left: 0px;
	padding-right: 0px;
}

.category-sections {
	display: flex;
	justify-content: space-between;
	align-items: normal;
}

.show-detail-lg {
	display: flex;
}

.container-box {
	display: flex;
	justify-content: flex-end;
	margin-top: 2rem;
}
.integer-box {
	display: flex;
	justify-content: center;
	background-color: var(--grey);
	height: 14rem;
	width: 14rem;
	border-radius: 2rem;
}
.digit {
	display: flex;
	align-items: center;
	font-size: 6rem;
	margin-top: -1rem;
	color: var(--limegreen);
}
.design-99 {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 2rem;
}
.freelance-market {
	font-size: 1.25rem;
	display: flex;
	justify-content: flex-start;
}
.font-99designs {
	font-size: 3rem;
	color: var(--grey);
}
.digit-font {
	margin-bottom: inherit;
	font-size: 1.5rem;
}

@media screen and (max-width: 992px) {
	.button-sellers {
		background-color: #ffd50a;
		font-weight: 600;
		color: var(--black);
		height: 3.3rem;
		border-color: #a5a5a5;
		width: 110px;
	}
	.margin_left {
		margin-left: 0;
	}
	.Happy {
		margin: -3rem;
	}
	.Awesome {
		margin: -1.5rem;
	}
	.Satisfied {
		margin: -0.25rem;
	}
	.Frustrated {
		margin: 1rem;
	}
	.Grr {
		margin: 2.25rem;
	}
	.Disappointment {
		margin: 3.5rem;
	}
	.buttons-container {
		margin-top: 0rem;
		margin-left: 0rem;
	}
	.container-box {
		display: flex;
		justify-content: center;
		margin-top: 2rem;
	}
	.design-99 {
		padding-top: 1rem;
		margin-left: 0rem;
		margin-top: 0rem;
		align-items: start;
	}
	.freelance-market {
		margin-left: 0.5rem;
	}
	.font-99designs {
		margin-left: 0rem;
	}
	.font-seller-profile {
		margin-left: 0rem;
	}
	.buttons-container {
		margin-left: -0.2rem;
	}
	.integer-box {
		height: 12rem;
		width: 12rem;
	}
}

@media screen and (max-width: 767px) {
	.design-99 {
		padding-top: 1rem;
		margin-left: 0rem;
		margin-top: 0rem;
		align-items: start;
	}
	.digit {
		display: flex;
		align-items: center;
		font-size: 5rem;
		margin-top: 0rem;
		color: var(--limegreen);
	}
	.buttons-container {
		margin-left: 0rem;
		justify-content: center;
	}
	.Frustrated-margin {
		margin-left: 30px;
	}
	.container-box {
		justify-content: center;
		margin-top: 1rem;
	}
	.font-99designs {
		margin-left: 0rem;
	}
	.font-seller-profile {
		margin-left: 0rem;
	}
	.freelance-market {
		display: flex;
		justify-content: center;
		margin-left: -2.5rem;
	}
	.Happy {
		position: relative;
		margin: -3.4rem;
		filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
	}
	.Awesome {
		position: relative;
		margin: -3rem;
		filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
	}
	.Satisfied {
		position: relative;
		margin: -2.5rem;
		filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
	}
	.Frustrated {
		position: relative;
		margin: -1.8rem;
		filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
	}
	.Grr {
		position: relative;
		margin: -0.8rem;
		filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
	}
	.Disappointment {
		position: relative;
		margin: 0.3rem;
		filter: drop-shadow(rgb(100, 100, 100) 3px 2px 2px);
	}

	.integer-box {
		height: 11rem;
		width: 11rem;
		border-radius: 1rem;
		margin-right: 8px;
	}
}

@media screen and (max-width: 600px) {
	.show-detail-lg {
		display: none;
	}
	.button-sellers {
		background-color: #ffd50a;
		font-weight: 600;
		color: var(--black);
		height: 3rem;
		border-color: #a5a5a5;
		width: 90px;
	}
	.category-sections {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	.integer-box {
		height: 7rem;
		width: 7rem;
		border-radius: 1rem;
		margin-right: 8px;
	}
	.font-seller-profile {
		font-size: 0.8rem;
		margin-top: 0rem;
	}
	.freelance-market {
		font-size: 1rem;
		margin-left: 0rem;
		margin-top: 10px;
	}
	.font-99designs {
		font-size: 1.3rem;
		margin-left: 0rem;
	}
	.classification-font-size {
		font-size: 0.8rem;
	}
	.button-sellers {
		font-size: 0.8rem;
	}
	.love-button {
		margin-top: 0px;
		line-height: normal;
	}
	.air-button {
		margin-top: 0px;
		line-height: normal;
	}
	.tell-button {
		margin-top: 0px;
		margin-left: 0px;
		line-height: normal;
	}

	.sup {
		display: none;
	}
}

@media screen and (max-width: 3380px) {
	.buttons-container {
		margin-left: -0.3rem;
	}
}
