.sellerchoice-wrapper-container {
	margin-top: -1.5rem;
}
.seller-choice-home {
	width: fit-content !important;
}
.autoComp-height {
	height: 0px;
}
.seller-choice-btn {
	width: 100px;
}
.seller-choice-height {
	height: 100px;
}
.btn-click-size {
	font-size: 14px;
}
.button-seller {
	background-color: #ffd50a;
	font-weight: 600;
	color: var(--black);
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 14px !important;
	border-color: #a5a5a5;
}
.button-seller-margin {
	margin-right: -1rem;
	margin-left: -1rem;
}
.button-seller:hover {
	background-color: var(--grey);
	color: var(--white);
}

.working-image {
	width: 6% !important;
	margin-left: 0.5rem;
	margin-top: -1.8rem;
}
.btn-diff-display {
	display: flex;
}
.btn-small-screen {
	display: none;
}

.to-click-link {
	text-decoration: none;
}

@media screen and (max-width: 992px) {
	.sellerchoice-wrapper-container {
		margin-top: 2.5rem;
	}
	.button-seller-margin {
		margin-bottom: 0.2rem;
	}
	.button-seller {
		margin-top: 4rem;
		margin-bottom: -2rem;
	}
	.click-tell-button {
		margin-bottom: 4rem;
	}
	.seller-choice-height {
		height: 100px;
	}
}

@media screen and (max-width: 600px) {
	.btn-diff-display {
		display: none;
	}
	.btn-small-screen {
		display: flex;
	}
	.seller-choice-height {
		height: 75px;
	}
	.button-seller {
		background-color: var(--yellow);
		color: var(--black);
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		font-size: 12px !important;
		border-color: #a5a5a5;
	}
	.seller-choice-btn {
		width: 70px;
	}
}

@media screen and (max-width: 768px) {
	.seller-choice-height {
		height: 60px;
	}
	.button-seller {
		margin-top: 0rem;
		margin-bottom: 0rem;
	}
}

@media screen and (max-width: 400px) {
	.seller-choice-height {
		height: fit-content;
	}
	.seller-choice-btn {
		width: 200px !important;
		display: flex;
		justify-content: center;
		margin-bottom: 10px;
	}
}