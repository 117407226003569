.contact_form {
	margin-bottom: 2rem;
}
.contact-image-width {
	width: 25%;
}

.input-fields-width {
	width: 75% !important;
}

.contactus-textarea {
	height: 10rem;
}
.contactUs-wrapper-container {
	margin-bottom: 15px;
}
.contactus-btn {
	background-color: rgb(207, 255, 76);
	color: var(--black);
	font-size: 16px;
	font-weight: bold;
	width: 7rem;
}
.contactus-btn:hover {
	background-color: rgb(182, 246, 7);
}
.contactus-btn:active {
	background-color: rgb(182, 246, 7);
	color: var(--black);
}

.form-control:focus {
	outline: none;
	box-shadow: none;
	border: 1px solid rgb(62, 123, 253);
}
.contact-email:focus {
	border: 1px solid rgb(62, 123, 253) !important;
}

@media only screen and (min-width: 450px) {
	.contact_form {
		margin-bottom: 1rem;
	}
}

@media only screen and (min-width: 550px) {
	.contactUs-form {
		width: 600px;
	}
}

@media only screen and (max-width: 550px) {
	.contactUs-form {
		width: 100%;
	}
	.contact-image-width {
		width: 50%;
	}

	.input-fields-width {
		width: 90% !important;
	}
}
