.feeling-lucky {
	font-size: 20px;
	color: #1875eb !important;
	margin-left: 2rem;
}

.search-modal-parent {
	padding-right: 0px !important;
}

.l2a-btn {
	background-color: var(--limegreen);
	color: var(--black);
	/* width: 15%; */
	border: none;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: 18px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
	padding-top: 6px;
	padding-bottom: 6px;
}
.l2a-btn:hover {
	background-color: rgb(199, 243, 78);
}
.search-button {
	font-size: 20px;
	margin-left: 2rem;
	color: #1875eb !important;
}
.feeling-lucky-container {
	margin-top: -1rem !important;
}
.feeling-lucky-disable {
	background-color: var(--limegreen);
	color: var(--black);
	/* width: 15%; */
	padding-left: 1rem;
	padding-right: 1rem;
	border: none;
	font-size: 18px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
	padding-top: 6px;
	padding-bottom: 6px;
	opacity: 0.7;
}

.toolTip-display {
	display: none;
}

.feeling-lucky-btn {
	background-color: var(--limegreen);
	color: var(--black);
	/* width: 15%; */
	padding-left: 1rem;
	padding-right: 1rem;
	border: none;
	font-size: 18px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
	padding-top: 6px;
	padding-bottom: 6px;
}
.feeling-lucky-btn:hover {
	background-color: rgb(199, 243, 78);
}
.feeling-lucky-coming-soon {
	font-size: 14px;
}

.css-yem0l2-MuiPopper-root-MuiTooltip-popper {
	margin-bottom: -10px !important;
}

.css-ja5taz-MuiTooltip-tooltip {
	font-size: 14px !important;
}

.coming-small {
	display: none;
}

@media screen and (max-width: 768px) {
	.coming-small {
		bottom: 16%;
		left: 20%;
		display: flex;
		position: absolute;
		font-size: 1rem;
	}
}

@media screen and (max-width: 450px) {
	.feeling-lucky {
		font-size: 20px;
		color: #1875eb !important;
		margin-left: 0rem;
	}
	.search-button {
		font-size: 20px;
		margin-left: 0rem;
		color: #1875eb !important;
	}
}
