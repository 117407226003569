.animation {
	position: relative;
	animation-duration: 2s;
	animation-delay: 0.3s;
	animation-name: bounce;
	filter: drop-shadow(rgb(102, 102, 102) 5px 5px 5px);
}
.love-remarks-title {
	font-size: 1.2rem;
	font-weight: 600;
}
.tell-story-balloon {
	cursor: pointer;
}
@keyframes bounce {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(-40px);
	}
	50% {
		transform: translateY(5px);
	}
	70% {
		transform: translateY(-25px);
	}
	80% {
		transform: translateY(2px);
	}
	90% {
		transform: translateY(-15px);
	}
	100% {
		transform: translateY(0);
	}
}

.love_shadow {
	text-decoration-line: underline;
}
.love_shadow1 {
	text-decoration-line: underline;
}
.love_shadow2 {
	text-decoration-line: underline;
}
.air_shadow {
	text-decoration-line: underline;
}
.air_shadow1 {
	text-decoration-line: underline;
}
.air_shadow2 {
	text-decoration-line: underline;
}
.remark_image_heading {
	font-size: 1rem;
	font-weight: 600;
}

@media screen and (max-width: 992px) {
	.remark_image_heading {
		font-size: 16px;
		font-weight: 600;
	}
	.love-remarks-title {
		font-size: 1rem !important;
		font-weight: 500 !important;
	}
}

@media screen and (max-width: 1200px) {
	.remark_image_heading {
		font-size: 8px;
		font-weight: 600;
	}
	.love-remarks-title {
		font-size: 0.9rem;
		font-weight: 500;
	}
}

@media screen and (max-width: 1800px) {
	.remark_image_heading {
		font-size: 13px;
		font-weight: 600;
	}
}
