.review-modal-notify-btn-winner {
	color: var(--black);
	font-weight: 800;
	background-color: #feffd0 !important;
	border: 2px solid #000;
}

.review-modal-msg {
	min-height: fit-content;
	max-height: 110px;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 5px;
	border-radius: 5px;
	text-align: start;
	padding-right: 15px; /* Adjust as needed to avoid scrollbar overlap */
	margin-right: -15px;
}

.review-inner-p {
	font-size: 16px !important;
}

.emoji-badge-review-modal {
	background-color: transparent !important;
	margin-top: 0px;
	position: absolute;
	top: -9px;
	right: -16px;
	font-size: 20px;
}

.winner-text {
	font-size: 20px;
}

.review-modal-notify-btn-winner:hover {
	background-color: #feffd0 !important;
	border: 2px solid #000;
	color: var(--black);
	font-weight: 800;
}

.awesome-dropdown-item-border {
	/* border: 10px solid #ffeea7 !important; */
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px #ffeea7;
}
.happy-dropdown-item-border {
	/* border: 10px solid var(--limegreen) !important; */
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px var(--limegreen);
}
.latest-review-badge {
	background-color: var(--blue) !important;
	margin-top: 0px;
	right: -5px;
	position: absolute;
}
.latest-review-main-div {
	margin: 0% 2%;
}
.satisfied-dropdown-item-border {
	/* border: 10px solid #73d3e3 !important; */
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px #73d3e3;
}
.Disappointe-dropdown-item-border {
	/* border: 10px solid #f29fde !important; */
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px #f29fde;
}
.Frustrate-dropdown-item-border {
	/* border: 10px solid #84bee0 !important; */
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px #84bee0;
}
.Grr-dropdown-item-border {
	/* border: 10px solid #eb8c8a !important; */
	border: 1px solid black !important;
	box-shadow: inset 0px 0px 2px 4px #eb8c8a;
}

.review-modal-header-date {
	margin: calc(-0.5 * var(--bs-modal-header-padding-y))
		calc(-0.5 * var(--bs-modal-header-padding-x))
		calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-header .btn-close {
	margin: -8px -8px -8px 0px !important;
	position: absolute;
	right: 8px;
	top: 13px;
}

.seller-name-animate {
	display: inline-block;
	animation: weeee 6s linear infinite alternate;
}

.review-seller-height {
	height: fit-content;
	margin-top: 2rem;
}

.nominated-btn {
	height: 38px;
}

.nominated-text {
	font-size: 20px;
	font-weight: 500;
}

.review-modal-seller-name {
	font-size: 2.5rem;
	margin-top: 1.2rem;
}

@keyframes weeee {
	0% {
		color: red;
	}
	25% {
		color: blue;
	}
	50% {
		color: green;
	}
	75% {
		color: orange;
	}
	100% {
		transform: translateX(50%);
		color: purple;
	}
}

.latest-balloons-name-animate {
	display: block;
	animation: ball 2s linear infinite alternate;
}

@keyframes ball {
	0% {
		color: red;
	}
	25% {
		color: blue;
	}
	50% {
		color: green;
	}
	75% {
		color: orange;
	}
	100% {
		transform: translateY(50%);
		color: purple;
	}
}

@media screen and (max-width: 600px) {
	.nominated-text {
		font-size: 12px !important;
		font-weight: 300 !important;
		white-space: nowrap;
	}
	.review-seller-height {
		height: fit-content;
		margin-top: 0rem;
	}
}

@media screen and (max-width: 768px) {
	.review-seller-height {
		height: fit-content;
		margin-top: 0rem;
	}
	.review-inner-p {
		font-size: 14px !important;
	}

	.winner-text {
		font-size: 14px !important;
	}
	.review-modal-notify-btn-winner:hover {
		background-color: #feffd0 !important;
		color: var(--black);
		font-weight: 500;
		font-size: 14px;
		border: 2px solid #000;
	}
	.latest-review-main-div {
		margin: 0% 0%;
	}
	.review-modal-seller-name {
		font-size: 1rem;
		margin-top: 0rem;
		margin-right: 3px;
	}
}
