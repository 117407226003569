.best-writer-center {
	height: 50vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	font-weight: 600;
}

.best-writer-dialog {
	padding: 30px !important;
}

.send-email-btn {
	text-transform: capitalize !important;
	padding: 7px 12px !important;
	height: fit-content;
	background: #dcf788 !important;
	color: black !important;
}

.winner-date-dialog {
	height: 340px !important;
}

.winner-date-margin {
	margin-top: 30px;
	margin-bottom: 30px !important;
}
