.air_shadow {
	text-decoration-line: underline;
}
.air_shadow1 {
	text-decoration-line: underline;
}
.air_shadow2 {
	text-decoration-line: underline;
}

.air-seller-cursor {
	cursor: pointer;
}
