.link_404 {
	background-color: var(--limegreen);
	color: var(--black);
	border: none;
	font-size: 18px;
	text-align: center;
	border-radius: 20px;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: 5px;
	padding-bottom: 5px;
}
.link_404:hover {
	background-color: rgb(199, 243, 78);
	color: var(--black);
}
.link_404:active {
	background-color: rgb(199, 243, 78) !important;
	color: var(--black) !important;
}

.four_zero_four_bg h1 {
	font-size: 80px;
}

.four_zero_four_bg h3 {
	font-size: 80px;
}
