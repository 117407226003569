.seller_profile_container {
	margin-bottom: 3rem;
}

@media screen and (max-width: 450px) {
	.seller_profile_container {
		margin-bottom: 0rem;
	}
}

.mobile-other-seller-link {
	background-color: var(--grey);
	text-align: center;
	color: var(--limegreen);
	margin-bottom: -2rem;
	font-size: 1.2rem;
}
