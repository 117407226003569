.button-next {
	background-color: rgb(207, 255, 76);
	color: black;
	width: 7rem;
	font-size: 16px;
	font-weight: bold;
}

.button-next:hover {
	color: var(--black);
	background-color: rgb(182, 246, 7);
	font-weight: bold;
}

@media screen and (max-width: 295px) {
	.button-next {
		margin-bottom: 9rem;
	}
}

@media screen and (max-width: 550px) {
	.button-next {
		margin-bottom: 5rem;
	}
	.button-pad {
		padding-bottom: 15%;
	}
}
