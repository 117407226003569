.child-height {
	min-height: 100vh;
}

.child-home-height {
	min-height: 100vh;
}

@media screen and (max-width: 500px) {
	.child-height {
		min-height: 100vh;
	}
	.child-home-height {
		min-height: 100vh;
	}
}

.profile-page-background-image {
	background-image: url("../../../../public/icons/seller-profile-2.png");
	/* height: 80% !important; */
	/* opacity: 0.6; */
	/* Center and scale the image nicely */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
