.admin-review-msg-search {
	margin-top: 1.5rem;
	margin-bottom: 2rem;
}
.admin-review-panel-cursor {
	cursor: pointer;
	font-size: 0.7rem;
}
.review-msg {
	border: 1px solid #867b7b;
	min-height: 250px;
	max-height: 250px;
	overflow-y: scroll;
	padding: 5px;
}
select:focus {
	outline: none;
	border-radius: 2px solid red !important;
}
.review-select {
	background-color: white !important;
	padding: 0.6rem;
	margin-top: 2px;
	border: 1px solid #ccc;
}
.review-user-text-field:focus {
	border: 2px solid blue;
}
.flag-Icon {
	color: grey;
	font-size: 0.7rem;
	padding-top: 3px;
}
.review-datePicker-child {
	position: relative;
	z-index: 100;
	display: flex;
	justify-content: flex-start;
	margin-bottom: 20px;
}

.css-i4bv87-MuiSvgIcon-root {
	width: 1.5rem !important;
	height: 1.5rem !important;
}

@media screen and (max-width: 574px) {
	.admin-review-msg-search {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	.admin-review-searchs {
		margin-bottom: 2rem;
	}
}

@media screen and (max-width: 870px) {
	.review-datePicker-child {
		position: relative;
		z-index: 100;
		display: flex;
		flex-direction: column;
	}
}

@media screen and (max-width: 992px) {
	.admin-review-msg-search {
		margin-top: 1.5rem;
		margin-bottom: 2rem;
	}
	.admin-review-searchs {
		margin-top: 1rem;
	}
}
