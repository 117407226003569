.admin-login-form {
	margin-bottom: 7rem;
}

.admin-login-container {
	width: 50%;
}

.loaderCenter {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 100;
	display: flex;
	align-items: center;
}

.admin-login-link {
	text-align: center;
}
.paragraph {
	font-size: 12px;
	color: blue;
}
.admin-login {
	background-color: rgb(182, 246, 7);
	color: var(--black);
	width: 30%;
	border: none;
	font-size: 18px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
}

.admin-login:hover {
	background-color: rgb(199, 243, 78);
	color: var(--black);
}
.admin-login:active {
	background-color: rgb(199, 243, 78) !important;
	color: var(--black) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

@media screen and (max-width: 400px) {
	.admin-login-link {
		font-size: 13px;
		padding-left: 10px;
		padding-right: 10px;
	}
	.admin-login {
		width: 50%;
	}
}

@media screen and (max-width: 450px) {
	.admin-login-form {
		margin-bottom: 10rem;
	}
}

@media screen and (max-width: 600px) {
	.admin-login-container {
		margin-bottom: 5rem;
		width: 75%;
	}
}
