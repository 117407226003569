.youtubeModal::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.youtubeModal {
  -ms-overflow-style: none;
}

.vedio-width {
  width: 100%;
  height: 500px
}

.side-vedio-width {
  width: 97%;
  height: 290px;
}

/* .modal-content {
  background-color: grey;
} */


@media screen and (max-width: 600px) {
	.vedio-width {
   display: none;
  }
  
  .side-vedio-width {
    width: 90%;
    display: flex;
    justify-content: center;
    height: 290px;
  }
  
}
