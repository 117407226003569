.category-submit-button {
	/* background-color: var(--yellow); */
	background: linear-gradient(213.34deg, #2d5fdb 11.02%, #091431 80.24%);
	color: var(--white);
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 14px !important;
	border-color: #a5a5a5;
}
.category-submit-button:hover {
	/* background-color: var(--black); */
	background: linear-gradient(213.34deg, #2d5fdb 11.02%, #091431 80.24%);
	font-size: 14px !important;
	color: var(--white);
}
.cat-approve-color {
	color: green !important;
}
.cat-pending-color {
	color: blue !important;
}
.cat-rejected-color {
	color: red !important;
}
.category-panel-cursor {
	cursor: pointer;
}
input {
	border: 1px solid #ccc;
}
input:focus {
	outline: none;
}
.admin-category-select {
	padding: 0.9rem;
	border-radius: 5px;
	border: 1px solid #ccc;
	background-color: white;
}
.admin-category-text-field {
	padding: 0.7rem !important;
	background-color: white;
	border-radius: 4px;
}
.admin-category-text-field:focus {
	border: 2px solid blue;
}
select:focus {
	outline: none;
	border: 2px solid blue;
}
.category-edit {
	padding: 0.7rem !important;
	background-color: white;
	border-radius: 4px;
}
.category-edit:focus {
	border: 2px solid blue;
}

.admin-search-category {
	margin-bottom: 2rem;
	border-radius: 3px;
}

.admin-search-category:focus {
	border: 2px solid blue;
}
.MuiTablePagination-selectLabel {
	margin-bottom: 0 !important;
}
.MuiTablePagination-displayedRows {
	margin-bottom: 0 !important;
}
select option:focus {
	background-color: yellow !important;
}
