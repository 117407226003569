.beta-paragraph {
	color: var(--grey);
}
.beta-signup-btn {
	background-color: rgb(182, 246, 7);
	color: var(--black);
	font-size: 18px;
}

.beta-signup-btn:hover {
	background-color: rgb(199, 243, 78);
	font-size: bold;
	color: var(--black);
}
.btn_signup {
	display: flex;
	justify-content: center;
}
