.editSellerParent {
	width: 300px;
	height: fit-content;
	padding: 20px;
}
.admin-seller-badge {
	background-color: rgb(207, 255, 76) !important;
	color: var(--black);
	margin-top: -13px;
	margin-left: -9px;
	position: absolute;
	right: -9px;
}
.seller-approve-color {
	color: green !important;
}
.seller-pending-color {
	color: blue !important;
}
.seller-rejected-color {
	color: red !important;
}

.admin-seller-name-link {
	cursor: pointer;
	text-decoration: none;
	color: black;
}
.seller-panel-cursor {
	cursor: pointer;
}
.all-sellers-center {
	display: flex;
	justify-content: center;
}

.all-sellers-child-center {
	display: flex;
	justify-content: space-between;
}

.addCategoryBtn-notActive {
	text-transform: capitalize !important;
	padding: 10px 15px !important;
	height: fit-content;
	color: black !important;
	background: white !important;
}

.addCategoryBtn-active {
	text-transform: capitalize !important;
	padding: 10px 15px !important;
	text-transform: capitalize !important;
	height: fit-content;
	background: #dcf788 !important;
	color: black !important;
}

.editSellerHeading {
	text-align: center;
	margin-top: 30px;
}
.inputParent {
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	margin-top: 20px;
	margin-right: 20px;
}

.loaderCenter {
	position: absolute;
	align-content: center;
	width: 100%;
	height: 100%;
	z-index: 100;
	align-items: center;
}

.inputField {
	padding: 5px;
}

.buttonDiv {
	display: flex;
	justify-content: center;
	margin-top: 15px;
}

.category {
	width: 220px;
	height: 30px;
	margin-left: 20px;
	margin-top: 20px;
	margin-bottom: 30px;
}
/* .seller-textfield {
  margin-left: -7rem;
} */
.auto-category-seller-select {
	width: 100%;
	/* height: 30px; */
	/* margin-top: 20px; */
	margin-bottom: -2.7rem;
}

.auto-category-seller {
	width: 100%;
	/* height: 30px; */
	/* margin-top: 20px; */
	margin-bottom: -1.2rem;
}
.admin-search-seller {
	border-radius: 3px;
}

.admin-search-seller:focus {
	border: 2px solid blue;
}
input {
	border: 1px solid #ccc;
}
input:focus {
	outline: none;
}
.admin-seller-text-field:focus {
	border: 2px solid blue;
}
select:focus {
	outline: none;
	border: 2px solid blue;
}
.admin-seller-text-field {
	padding: 0.7rem;
	background-color: white;
	border-radius: 4px;
}

.admin-seller-select {
	padding: 0.9rem;
	border-radius: 5px;
	border: 1px solid #ccc;
	background-color: white;
}

.sellerInputParent {
	display: flex;
	justify-content: flex-end;
	height: 2.7rem;
	margin-top: 0.29rem;
}
.seller-submit-button {
	background: #dcf788;
	color: #000000;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 14px !important;
	border-radius: 0.5rem;
	font-size: 1.4rem;
}

.seller-submit-button:hover {
	/* background-color: var(--black); */
	background: #dcf788;
	color: #000000;
}

.swal2-popup .swal2-styled {
	margin: 0px 5px 0 !important;
}

@media screen and (max-width: 575px) {
	.sellerInputParent {
		justify-content: flex-start;
		margin-bottom: 2rem;
	}
}
