.change-form {
    margin-top: 7rem;
}
.login-link {
    text-align: center;
}
.pointer:hover {
    cursor: pointer;
}
.widthh {
    width: 20%;
    height: auto;
}
.profile-form-width {
  width: 70%;
}
.mx-auto {
    margin-inline: auto;
}
.px-auto {
    padding-inline: auto;
}
.w-55 {
    width: 50%;
}
.profile-img-rounded {
  border-radius: 50%;
}
.w-52 {
    width: 13rem;
}
.h-40 {
    height: 10rem;
}
.ml-13 {
    margin-left: 2%;
}
.ml-2 {
    margin-left: 5%;
}
.pt-15 {
    padding-top: 5%;
}
.pb-15 {
    padding-bottom: 5%;
}
.paragraph {
    font-size: 12px;
    color: blue;
}
.changepassword {
    background-color: rgb(199, 243, 78);
    text-decoration: none;
    color: var(--black);
    width: fit-content;
    border: none;
    font-size: 0.8rem;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 20px;
}
@media screen and (max-width: 550px) {
    .changepassword {
       width: 50%;
       font-size: 15px;
    }
  .profile-form-width {
    width: 100%;
  }
}
@media screen and (max-width: 475px) {
    .change-form {
       margin-bottom: 15rem;
    }
}

.error-size {
    font-size: 12px;
}
@media screen and (max-width: 450px) {
    .change-form {
       margin-bottom: 10rem;
    }
    .login-link {
       font-size: 16px;
       padding-left: 10px;
       padding-right: 10px;
    }
}

@media screen and (max-width: 350px) {
    .forgot {
       width: 55%;
    }
}
.changepassword:hover {
    background-color: rgb(182, 246, 7);
    color: var(--black);
}
.changepassword:active {
    background-color: rgb(182, 246, 7) !important;
    color: var(--black) !important;
}
.changepassword:focus {
    background-color: rgb(182, 246, 7) !important;
    color: var(--black) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}