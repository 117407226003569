.loaderCenter {
	position: absolute;
	align-content: center;
	width: 100%;
	height: 100%;
	z-index: 100;
	align-items: center;
}
.progressColor {
	color: gray !important;
}
