.winner-font {
	font-size: 1.4rem;
}
.bestwriterandseller-wrapper-container {
	margin-bottom: 20px;
}
.inst-modal-parent {
	display: flex;
	flex-direction: row;
}
.isntgram-img-height {
	height: 400px;
}
.text-animation-p-div {
	width: 100%;
	height: 50%;
	overflow: hidden;
	padding: 0;
	margin-bottom: 16px;
}
.inner-text-animation {
	text-align: center;
	height: 100%;
	padding: 16px;
	margin: 0;
	animation: 2s anim-lineUp ease-out infinite;
}
@keyframes anim-lineUp {
	0% {
		opacity: 0;
		transform: translateY(80%);
	}
	20% {
		opacity: 0;
	}
	50% {
		opacity: 1;
		transform: translateY(0%);
	}
	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}
.seller-link-captilize {
	text-transform: uppercase;
	margin-left: 6px;
	margin-right: 4px;
}
.insta-imag-col-margins {
	margin-top: 30px;
	margin-bottom: 100px !important;
}

.simple-text-space {
	white-space: nowrap;
}

.instagram-innerImage {
	width: 100%;
	height: 300px !important;
	cursor: pointer;
}
.instagram-innerImage a {
	text-decoration: none;
	font-weight: 600;
	float: right;
	color: rgb(13, 110, 253) !important;
}
.instagram-modal-link {
	text-decoration: none;
	cursor: pointer;
}
.instgram-modal-dark {
	color: black;
}
.modal-parent {
	padding: 0px !important;
}
.winner-cut-heading {
	color: black;
	font-size: 2rem;
	font-weight: 500;
}
.third-image-class {
	height: 318px;
	margin-left: 17px !important;
	margin-top: 0px !important;
}
.first-image-class {
	height: 320px !important;
}
.insta-story-modal-text {
	padding: 20px !important;
}
.insta-parent-div {
	margin-bottom: 100px;
}
.insta-inner-div-anchor {
	color: blue !important;
}
.insta-photo-div {
	position: absolute;
	left: 0;
	width: 98%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.4rem;
}
.insta-book-margin {
	margin-left: 40px !important;
}
.insta-card-text {
	position: absolute;
	left: 10px;
	margin-top: 24px !important;
}
.insta-heading {
	font-size: 20px;
	font-weight: 600;
}
.insta-date {
	opacity: 0.6;
	font-size: 14px;
}

.insta-date-val {
	font-size: 20px;
}
.instgram-row {
	padding-left: 10%;
}
.story-link {
	color: rgb(0, 0, 255) !important;
}
.contest-rules-margin {
	margin-top: 70px;
}

.winner-rules-margin {
	margin-top: 70px;
}
.img_width {
	width: 11%;
}
.best-writer-sub-headings {
	font-size: 1.5rem;
	font-weight: 600;
}
.best-shirt-width {
	width: 25%;
}
.ul-margin {
	margin-left: 2rem;
}
.relation-margin {
	margin-left: 5.8rem;
}
.paragraph-margin {
	margin-left: 5rem;
}
.guidline-heading-coming-soon {
	font-size: 2rem;
	font-weight: 600;
}

.comingSoonanimation {
	height: 50px;
	overflow: hidden;
	margin-left: 1rem;
}

.animation > div > h1 {
	height: 2.81rem;
	margin-bottom: 2.81rem;
}

.animation div:first-child {
	animation: text-animation 8s infinite;
}

.first h1 {
	color: #8865f5;
}
.second h1 {
	color: #8865f5;
}
.third h1 {
	color: #8865f5;
}

@keyframes text-animation {
	0% {
		margin-top: 0;
	}
	10% {
		margin-top: 0;
	}
	20% {
		margin-top: -5.62rem;
	}
	30% {
		margin-top: -5.62rem;
	}
	40% {
		margin-top: -11.24rem;
	}
	60% {
		margin-top: -11.24rem;
	}
	70% {
		margin-top: -5.62rem;
	}
	80% {
		margin-top: -5.62rem;
	}
	90% {
		margin-top: 0;
	}
	100% {
		margin-top: 0;
	}
}

.insta-modal-img {
	width: 100%;
}

@media screen and (max-width: 490px) {
	.insta-card-text {
		position: relative;
		left: 0px;
		margin-top: 24px !important;
		margin-bottom: 60px !important;
	}
	.insta-imag-col-margins {
		margin-top: 0px !important;
		margin-bottom: 45px !important;
	}
	.third-image-class {
		height: 290px;
		margin-left: 17px !important;
		margin-top: 20px !important;
	}
	.insta-book-margin {
		margin-left: 10px !important;
	}
	.inst-modal-parent {
		display: flex;
		flex-direction: column;
	}
	.insta-modal-img {
		width: 100vw !important;
	}
	.winner-margin {
		padding-left: 0px !important;
	}
	.img_width {
		width: 25%;
	}
	.bestwriterandseller-wrapper-container {
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 767px) {
	.insta-book-margin {
		margin-left: 30px !important;
	}

	.contest-rules-margin {
		margin-top: 40px;
	}
	.winner-rules-margin {
		margin-top: 30px;
	}
	.winner-cut-heading {
		font-size: 1.5rem;
		font-weight: 500;
	}
	.img_width {
		width: 13%;
	}
	.best-shirt-width {
		width: 100%;
		margin-top: 10px;
	}
	.relation-margin {
		margin-left: 0rem;
	}
}

@media screen and (max-width: 420px) {
	.img_width {
		width: 23%;
	}

	.guidline-heading-coming-soon {
		font-size: 2rem !important;
		font-weight: 600 !important;
	}
}

@media screen and (max-width: 365px) {
	.img_width {
		width: 23%;
	}
}
