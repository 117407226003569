.len {
	width: 80%;
	margin-inline: auto;
}
.change-form {
	margin-top: 7rem;
}
.pr-2 {
	padding-right: 2rem;
}
.login-link {
	text-align: center;
}
.profile-form-width {
  width: 70%;
}
.w-60{
	width: 90%;
}
.w-55 {
	width: 50%;
}
.background-color {
	background-color: var(--limegreen);
	color: white;
	padding: 3rem 1rem 0 1rem;
}
.left-rounded-3 {
	border-top-left-radius: 1rem;
	border-bottom-left-radius: 1rem;
}
.profile-img-rounded {
  border-radius: 50%;
}
.w-52 {
	width: 9rem;
}
.h-40 {
	height: 7rem;
}
.ml-13 {
	margin-left: 2%;
}
.ml-2 {
	margin-left: 5%;
}
.paragraph {
	font-size: 12px;
	color: blue;
}
.buttons{
	color: black;
	border: none;
	background-color: white;
}
.change-passwor {
	background-color: rgb(199, 243, 78);
	color: var(--black);
	width: fit-content;
	border: none;
	font-size: 18px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
}
@media screen and (max-width: 550px) {
	.change-passwor {
		width: 50%;
		font-size: 15px;
	}
  .profile-form-width {
    width: 100%;
  }
}
@media screen and (max-width: 475px) {
	.change-form {
		margin-bottom: 15rem;
	}
}

.error-size {
	font-size: 12px;
}
@media screen and (max-width: 450px) {
	.change-form {
		margin-bottom: 10rem;
	}
	.login-link {
		font-size: 16px;
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media screen and (max-width: 350px) {
	.forgot {
		width: 55%;
	}
}
.change-passwor:hover {
	background-color: rgb(182, 246, 7);
	color: var(--black);
}
.change-passwor:active {
	background-color: rgb(182, 246, 7) !important;
	color: var(--black) !important;
}
.change-passwor:focus {
	background-color: rgb(182, 246, 7) !important;
	color: var(--black) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
