.fit-content {
    width: fit-content;
}
.around {
    justify-content: space-around;
}
.ml-20 {
    margin-left: 20%;
}
.justify-content-center {
    justify-content: flex-center;
}
.change-passwords {
	background-color: rgb(199, 243, 78);
	color: var(--black);
	width: fit-content;
	border: none;
	font-size: 18px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
}
.change-passwords:hover {
	background-color: rgb(182, 246, 7);
	color: var(--black);
}
.change-passwords:active {
	background-color: rgb(182, 246, 7) !important;
	color: var(--black) !important;
}
.change-passwords:focus {
	background-color: rgb(182, 246, 7) !important;
	color: var(--black) !important;
}