.bg-seller {
	background-color: #555555;
}
.categoryModalInput {
	width: 100%;
}
.add-seller-editor {
	z-index: 10;
	position: relative;
}
.disabled-input-color {
	background-color: #efefef;
}
.add-seller-information {
	font-size: 20px;
}
.select-classifications {
	cursor: pointer;
}
.categoryModalInput-hideField {
	width: 70%;
}
.add-seller-input-w {
	width: 75%;
}
.checkbox {
	display: flex;
	justify-content: center;
}
.padding-right-sellerType {
	padding-right: 12px;
}
.spanLine {
	padding-left: 10%;
}
.sellerTypeModalInput {
	width: 76%;
}
.seller-textfield {
	background-color: #ffffff;
	border-radius: 2rem;
}
.editor-width {
	width: 70%;
}
.coutries-input {
	width: 51.2%;
}
.address-height {
	height: fit-content;
}
.addseller-header {
	display: block;
	padding: 1rem 1rem 0rem 0.5rem;
}
.seller-classification-header {
	padding: 1rem 1rem 0rem 0.5rem;
}
/* .seller-input {
	display: flex;
	justify-content: flex-end;
} */
.click-color {
	color: rgb(54, 54, 130);
	margin-left: 5px;
	cursor: pointer;
}
.login-forgot {
	display: flex;
	justify-content: space-between;
	margin-top: 14px;
}
.forgot-link {
	margin-right: 16%;
	font-size: 17px;
	white-space: nowrap;
}
.sign-up-margin {
	margin-left: 58px;
	font-size: 18px;
	white-space: nowrap;
}
.reviewCenter {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;
}
/* .webAddress-input {
	display: flex;
	justify-content: flex-start;
} */
.add-seller-submit-button {
	background-color: rgb(207, 255, 76);
	color: var(--black);
	width: 8rem;
	border: none;
	font-size: 16px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
	font-weight: bold;
}
.add-seller-submit-button:hover {
	background-color: rgb(182, 246, 7) !important;
	color: var(--black) !important;
}
.add-seller-submit-button:active {
	background-color: rgb(182, 246, 7) !important;
	color: var(--black) !important;
}

.classification {
	width: 78%;
	margin-left: 14px;
}
.add-seller-decription-text {
	margin-bottom: -2rem;
}
.field-justify-content {
	justify-content: center;
}
.field-justify-content-sellerType {
	justify-content: center;
}
.dialog-blur {
	background: rgba(0, 0, 0, 0.5);
}
.detail-modal-parent {
	/* height: 500px; */
	/* overflow-y: scroll; */
}

.row-padding-right {
	padding-right: 8px;
}

.coutries-input-hide-field {
	width: 67%;
}

.addSeller-textarea-width {
	width: 50%;
}
.logo-width {
	width: 5%;
}
.addseller-heading {
	margin-top: 1.5rem;
	margin-left: 1.5rem;
	font-size: 1.5rem;
}
.forgetpassword-container {
	height: 10rem;
}
.login-or {
	margin-top: 2px;
	margin-left: 46px;
}
.login-logo {
	width: 10%;
}
.totell-or {
	margin-top: 3.4rem;
}
.imgballoon {
	display: flex;
}
.review-modal-image-heading {
	font-weight: bold;
	/* margin-top: 2.5rem; */
	margin-left: 2rem;
	font-size: 1.5rem;
}
.review-modal-image-shadow {
	filter: drop-shadow(rgb(65, 65, 65) 2px 2px 1px);
	width: 7rem;
	/* margin-left: 1rem; */
}
.review-modal-notify-btn {
	background-color: var(--limegreen) !important;
	color: var(--black);
	font-size: 18px;
}
.review-modal-notify-btn:hover {
	background-color: rgb(199, 243, 78) !important;
}
.review-modal-wrapper-container {
	background-color: white;
}
.review-modal-date-text {
	padding-right: 2rem;
	font-size: 1.2rem;
	margin-top: 1rem;
}
.review-modal-text-area {
	/* margin-top: 1rem; */
	margin-bottom: 1.3rem;
	/* height: 10rem; */
	width: 93%;
}
.review-modal-seller-name-text {
	text-align-last: center;
	font-size: 1.2rem;
	font-weight: 700;
	margin-top: 3.5rem !important;
	margin-left: -1rem;
	color: black !important;
}
.review-modal-user-name-icon {
	border-radius: 3rem;
	font-size: 3.5rem;
	color: grey;
	background-color: grey;
}
.review-modal-user-name {
	display: flex;
	align-items: center;
	font-size: 1.3rem;
	margin-left: 0.7rem;
	margin-top: 0.7rem;
}
.review-modal-flag-icon {
	color: grey;
	font-size: 1.4rem;
	text-align-last: right;
	margin-right: -1rem;
	/* padding-top: 3px; */
}
.review-modal-badge {
	background-color: var(--blue) !important;
	margin-top: 0.5rem !important;
	margin-left: -9px;
	position: absolute;
}
.review-modal-btn-div {
	text-align: center;
}
.sellerModalMargin {
	margin-top: 50px !important;
}
.review-modal-seller-balloon-btn {
	background-color: var(--limegreen) !important;
	color: var(--black);
	font-size: 13px;
}
.review-modal-seller-balloon-btn:hover {
	background-color: rgb(199, 243, 78) !important;
	color: var(--black);
}
.review-modal-nominate-button {
	text-align-last: right;
}

.loaderCenter {
	position: absolute;
	align-content: center;
	width: 100%;
	height: 100%;
	z-index: 100;
	align-items: center;
}

.to-tell-submit-buton {
	color: var(--black);
	width: 10rem;
	font-size: 16px;
	background-color: rgb(207, 255, 76);
	font-weight: bold;
}
.to-tell-submit-buton:hover {
	color: var(--black);
	background-color: rgb(182, 246, 7);
	font-weight: bold;
}
.to-tell-submit-buton:active {
	color: var(--black) !important;
	background-color: rgb(182, 246, 7) !important;
	font-weight: bold;
}
.login-submit {
	background-color: rgb(207, 255, 76);
	color: var(--black);
	width: 30%;
	border: none;
	font-size: 16px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
	font-weight: bold;
}
.login-submit:hover {
	color: var(--black);
	background-color: rgb(182, 246, 7);
}
.login-submit:active {
	background-color: rgb(182, 246, 7) !important;
	color: var(--black) !important;
}

.add-seller-review-section {
	margin-top: 1rem;
}

.sign-modal-input-width {
	width: 75%;
}

@media screen and (max-width: 420px) {
	.sign-up-margin {
		margin-left: 0px !important;
		font-size: 18px;
	}
	.review-modal-image-heading {
		font-size: 0.87rem;
		margin-top: 20px;
	}
	.review-modal-image-shadow {
		width: 4.3rem !important;
		margin-top: 0rem;
	}
	.imgballoon {
		margin-left: -1rem;
	}
	.review-modal-seller-name-text {
		font-size: 1rem;
		margin-top: 1.5rem !important;
		margin-bottom: 14px;
	}
	.logo-width {
		width: 15%;
	}
	.addseller-heading {
		margin-left: 0.2rem;
		font-size: 1.1rem;
	}
	.imgballoon {
		margin-left: -2rem;
	}
	.review-modal-date-text {
		font-size: 0.9rem;
		padding-right: 0.1rem;
		margin-top: 0rem;
	}
	.logo-width {
		width: 11%;
	}

	.review-modal-image-heading {
		font-size: 1rem;
	}
	.review-modal-image-shadow {
		width: 5rem;
		margin-top: -1rem !important;
	}
}

@media screen and (max-width: 768px) {
	.editor-width {
		width: 90%;
	}
	.sign-modal-input-width {
		width: 100%;
	}
	.sign-up-margin {
		margin-left: 0px;
		font-size: 18px;
	}
	.love-modal-input-mobile {
		width: 90%;
	}
	.review-modal-flag-icon {
		font-size: 1.3rem;
		margin-top: 0rem;
		margin-left: 0rem;
	}
	.review-modal-image-heading {
		font-size: 1.1rem;
	}
	.review-modal-image-shadow {
		width: 5rem;
		margin-top: -1rem !important;
	}
	.add-seller-input-w {
		width: 100%;
	}
	.add-seller-information {
		font-size: 16px;
	}
	.categoryModalInput-hideField {
		width: 100% !important;
	}
	.coutries-input-hide-field {
		width: 100%;
	}
	.classification {
		width: 82%;
		margin-left: 10px;
	}
	.coutries-input {
		width: 81%;
	}
	.detail-modal-parent {
		height: 500px;
		overflow-y: scroll;
	}
	.field-justify-content-sellerType {
		justify-content: center;
	}
	.field-justify-content {
		justify-content: center;
	}
	.spanLine {
		width: 100%;
		/* padding: 10%; */
	}
	.seller-input {
		justify-content: center;
	}
}

@media screen and (max-width: 992px) {
	.logo-width {
		width: 9%;
	}
	.review-modal-seller-name-text {
		font-size: 1rem;
		margin-bottom: 14px;
	}
	.addseller-heading {
		margin-left: 0.5rem;
		font-size: 1.2rem;
	}
	.addSeller-textarea-width {
		width: 100%;
	}
	.review-modal-notify-btn {
		font-size: 12px;
	}
	.review-modal-user-name-icon {
		font-size: 2.3rem;
	}
	.review-modal-user-name {
		font-size: 1rem;
	}
	.review-modal-flag-icon {
		font-size: 1.3rem;
		margin-top: -0.2rem;
	}
	.review-modal-nominate-button {
		text-align-last: left;
	}
	.review-modal-date-text {
		font-size: 0.9rem;
		margin-top: 0rem;
	}
	.review-modal-image-heading {
		font-size: 1.1rem;
		padding-left: 20px;
	}
	.review-modal-image-shadow {
		width: 5rem;
	}
	.review-modal-text-area {
		height: 10rem;
		width: 89%;
	}
}

@media screen and (max-width: 1200px) {
	.review-modal-notify-btn {
		background-color: var(--limegreen) !important;
		color: var(--black);
		font-size: 15px;
	}
	.review-modal-user-name-icon {
		font-size: 3rem;
	}
	.review-modal-user-name {
		font-size: 0.8rem;
	}
	.review-modal-flag-icon {
		font-size: 1.3rem;
	}
	.review-modal-image-heading {
		font-weight: bold;
		padding-left: 3px;
		font-size: 1.5rem;
	}
	.review-modal-notify-btn {
		font-size: 13px;
	}
	.review-modal-user-name-icon {
		font-size: 2.5rem;
	}
	.review-modal-user-name {
		font-size: 0.8rem;
	}
	.review-modal-flag-icon {
		font-size: 1.3rem;
		margin-left: 0.2rem;
	}
	.review-modal-image-heading {
		font-size: 1.2rem;
		margin-left: -0.4rem;
	}
	.review-modal-image-shadow {
		width: 5rem;
		margin-top: 0rem;
	}
}
