.child-header-logo {
	width: 4rem;
}
.child-beta-link {
	background-color: var(--limegreen);
	text-decoration: none;
	color: #1875eb;
	font-size: 1.5rem;
}
.child-beta-link:hover {
	color: #1875eb;
}
