.guidlines-wrapper-container {
	margin-bottom: 30px;
}
.tell-btn {
	background-color: #212529;
}
.refrain-btn {
	background-color: #116cf9;
}
.guidline-headings {
	font-size: 2rem;
	font-weight: 600;
}
.guidline-sub-headings {
	font-size: 1.5rem;
	font-weight: 600;
}
.guidline-sub-sub-headings {
	font-size: 1rem;
	font-weight: 600;
}
.guidline-headings-main {
	font-size: 2rem;
	font-weight: 600;
}
.impersonal-btn {
	background-color: #3e8855;
}
.trust-btn {
	background-color: #dc3545;
}
.avoid-btn {
	background-color: #31d2f2;
}
.advice-btn {
	background-color: #ffc107;
}
.guidelines-wrapper-container {
	margin-bottom: 200px;
}
@media screen and (max-width: 450px) {
	.guidlines-wrapper-container {
		margin-bottom: 20px;
	}
	.guidline-headings {
		font-size: 1.2rem !important;
	}
	.guidline-headings-main {
		font-size: 1.4rem;
	}
}
