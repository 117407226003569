.update-container {
	position: absolute;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.update-heading {
	font-size: 70px;
}
.update-button {
	background-color: rgb(182, 246, 7);
	color: var(--black);
	border: none;
	padding: 5px;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: 20px;
	text-align: center;
	border-radius: 5rem;
}
.update-button:hover {
	background-color: rgb(199, 243, 78);
	color: var(--black);
}
.update-button:active {
	background-color: rgb(199, 243, 78) !important;
	color: var(--black) !important;
}
.icon {
	margin-top: 0.75rem;
	margin-left: 1rem;
	margin-right: 1rem;
}
.main-div {
	width: 34%;
	display: flex;
	background: greenyellow;
}
.css-i4bv87-MuiSvgIcon-root {
	width: 1.5rem !important;
	height: 1.5rem !important;
}

@media screen and (max-width: 455px) {
	.update-heading {
		font-size: 40px;
	}
	.update-button {
		font-size: 15px;
	}
	.icon {
		margin-top: 0.55rem;
		margin-left: 0.4rem;
		margin-right: 0.4rem;
	}
	.main-div {
		width: 80%;
		font-size: 13px;
	}
	.css-i4bv87-MuiSvgIcon-root {
		width: 1.7rem !important;
		height: 1.7rem !important;
	}
}

@media screen and (max-width: 570px) {
	.update-heading {
		font-size: 50px;
	}
	.main-div {
		width: 65%;
	}
}

@media screen and (max-width: 645px) {
	.icon {
		margin-top: 0.55rem;
		margin-left: 0.4rem;
		margin-right: 0.4rem;
	}
	.main-div {
		width: 55%;
		font-size: 13px;
	}
	.css-i4bv87-MuiSvgIcon-root {
		width: 1.7rem !important;
		height: 1.7rem !important;
	}
}

@media screen and (max-width: 875px) {
	.icon {
		margin-top: 0.75rem;
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
	.main-div {
		width: 45%;
	}
}
