.user-submit-button {
	/* background-color: var(--yellow); */
	background: #dcf788;
	color: black;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	border-radius: 0.5rem;
	font-size: 1.4rem;
}
.user-panel-cursor {
	cursor: pointer;
}
.block-user-color {
	color: red !important;
}

.unblock-user-color {
	color: green !important;
}

.user-submit-button:hover {
	background: #dcf788;
	color: var(--white);
}
.admin-user-text-field {
	padding: 0.7rem !important;
	background-color: white;
	border-radius: 4px;
}
.admin-user-text-field:focus {
	border: 2px solid blue;
}

.admin-search-user {
	margin-bottom: 2rem;
	border-radius: 3px;
}

.admin-search-user:focus {
	border: 2px solid blue;
}
