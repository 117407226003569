.footer-text {
	color: var(--limegreen);
}
.footer {
	background-color: var(--grey);
	z-index: 10;
}
.footer-links {
	margin-left: 2rem;
	color: white;
	text-decoration: none;
	cursor: pointer;
}
.privacy-mobile {
	margin-left: -10px;
}
.footer-links:hover {
	color: var(--limegreen);
}
.footer-container {
	display: flex;
	justify-content: space-between;
}
.spanR {
	font-size: 1.1em;
}
.footer-link-wrap {
	white-space: nowrap;
}

@media screen and (max-width: 500px) {
	.footer-container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}
	.footer-links {
		font-size: 13px;
	}
	.footer-text {
		font-size: 13px;
	}
}

@media screen and (max-width: 585px) {
	.footer-links {
		margin-left: 0.5rem;
		font-size: 12px;
	}
	.footer-text {
		font-size: 11px;
	}
}

@media screen and (max-width: 790px) {
	.footer-links {
		margin-left: 1rem;
		font-size: 13px;
	}
	.footer-text {
		font-size: 13px;
	}
}
