.btnCenter {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
	margin-top: 30px;
}
.contact-email-submit-button {
	background: linear-gradient(213.34deg, #2d5fdb 11.02%, #091431 80.24%);
	color: var(--white);
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 14px !important;
	border-color: #a5a5a5;
}
.contact-approve-color {
	color: green !important;
}
.contact-pending-color {
	color: blue !important;
}
.contact-in-review-color {
	color: red !important;
}
.contact-email-submit-button:hover {
	background: linear-gradient(213.34deg, #2d5fdb 11.02%, #091431 80.24%);
	font-size: 14px !important;
	color: var(--white);
}
.contact-res-cursor {
	cursor: pointer;
}
.header-text-center {
	display: flex;
	width: 100%;
	justify-content: center;
}
