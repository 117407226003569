.links {
	color: var(--limegreen);
	text-decoration: none;
}
.links:hover {
	color: var(--limegreen);
}
.other-sellers-container {
	background-color: var(--grey);
	height: fit-content;
	padding-bottom: 20px;
	min-height: 50%;
	padding-right: 10px;
}
.other-no-seller {
	display: flex;
	justify-content: center;
	margin-top: 50px;
	margin-left: 26px;
	color: white;
}

.other-seller-arrow-icon {
	color: white !important;
}

.px-md-auto {
	padding-left: auto !important;
	padding-right: auto !important;
}

@media screen and (max-width: 575px) {
	/* .other-sellers-container {
		padding-right: 0px;
	} */

	.px-none {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}
