.navbar {
	background-color: var(--limegreen);
}
.report-bug-parent {
	position: relative;
}
.report-bug {
	display: none;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	visibility: hidden;
	opacity: 0;
}
.report-bug-parent:hover .report-bug {
	position: absolute;
	visibility: visible;
	opacity: 1;
	color: black;
	background-color: #fea9dd;
	cursor: pointer;
	width: 120px;
	border-radius: 4px;
	font-size: 16px;
	font-weight: 700;
	display: block;
	padding-left: 5px;
}
.top-navbar-parent {
	padding-top: 0.8rem !important;
	padding-bottom: 0.6rem !important;
}
.add-btn-header {
	width: 120px;
	margin-left: 20px;
}
.navbar-grid {
	width: 15% !important;
	overflow: hidden;
	padding-bottom: 70vh;
	position: absolute;
	z-index: 100;
	align-items: start;
	bottom: 0;
	top: 62px;
	right: 0;
}

.container-fluid-margin {
	margin-top: 4px;
}
.custom-menu-icon {
	color: #585454;
	border: 1px solid #585454;
	width: 42px !important;
	border-radius: 4px;
	cursor: pointer;
	height: 36px !important;
}
.nav_item {
	display: flex;
	flex-direction: row;
	width: 15% !important;
}
.header-logo {
	width: 2.9rem;
}
.navbar-toggler-desktop {
	display: block !important;
}
.headr-nav-width {
	width: 100%;
}
.header {
	background-color: var(--limegreen);
}
.beta-link {
	text-decoration: none;
	color: black;
	margin-left: 1.3rem;
}
.beta-link:hover {
	color: black;
}
.win_free {
	margin-left: 1.5rem;
	font-size: 14px;
	color: #1875eb !important;
}
.nav-links {
	text-decoration: none;
}
.visible {
	display: none;
}

.login-btn {
	margin-top: -0.5rem;
}
.login-btn:hover {
	background-color: rgb(199, 243, 78);
	color: var(--black);
	font-weight: bold;
}

.navigtion_div {
	margin-right: 3rem;
}
.search_icon {
	margin-right: 1rem;
}
.sidebar-content {
	width: 250px;
}
.popup_width {
	width: 35%;
}
.navbar_collapse {
	display: flex !important;
	justify-content: flex-end !important;
}
.search-seller-btn {
	--bs-btn-padding-x: none;
}
.header-footer-container {
	display: flex;
	flex-direction: column;
	margin-left: 20px;
}

.header-footer-container-desktop-login {
	display: flex;
	flex-direction: row;
	margin-left: 20px;
}
.header-footer-container-desktop {
	display: flex;
	flex-direction: row;
	margin-left: 20px;
}

.beta-parent-left {
	margin-left: 20px;
}
.footer-links-header {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

.activeDiv-header {
	cursor: pointer;
	color: #000000;
	text-decoration: none;
	/* margin-left: 18%; */
	background: linear-gradient(213.34deg, #dcf788 25.02%, #ffffff 80.24%);
	border-radius: 0.5rem;
	font-size: 1.4rem;
}

.catLink-header {
	cursor: pointer;
	color: #000000;
	text-decoration: none;
	font-size: 1.4rem;
	/* margin-left: 18%; */
}

.catLink-header:hover {
	background: linear-gradient(213.34deg, #dcf788 25.02%, #ffffff 80.24%);
	border-radius: 0.5rem;
}

@media only screen and (max-width: 435px) {
	.beta-link {
		font-size: 15px;
	}
	.win_free {
		margin-left: 2rem;
		margin-top: 0rem;
		font-size: 12px;
		display: flex;
		flex-wrap: wrap;
	}
	.login-btn {
		font-size: 15px;
	}
}

@media only screen and (max-width: 550px) {
	.header-logo {
		width: 3rem;
	}
	.beta-link {
		font-size: 15px;
	}
	.win_free {
		margin-left: 0.5rem;
		font-size: 12px;
		margin-left: 1.5rem;
		margin-top: 0rem;
		/* display: flex;
    flex-wrap: wrap; */
	}
	.login-btn {
		font-size: 15px;
	}
}

@media screen and (max-width: 1270px) {
	.catLink-header {
		color: #000000;
		text-decoration: none;
		font-size: 1rem;
		/* margin-left: 18%; */
	}
	.activeDiv-header {
		font-size: 1rem;
	}
	.beta-link {
		margin-left: 0.3rem;
	}
	.navbar-grid {
		width: 40% !important;
		align-items: start;
		top: 52px;
	}
	.nav_item {
		display: flex;
		flex-direction: row;
		width: 70% !important;
	}
}

@media screen and (max-width: 765px) {
	.add-btn-header {
		margin-left: 0px;
	}
	.header-footer-container-desktop-login {
		display: flex;
		flex-direction: row;
		margin-left: 13px;
	}
	.nav_item {
		display: flex;
		flex-direction: row;
		width: 100% !important;
	}
	.login-btn {
		margin-left: -0.3rem;
	}
	.beta-link {
		margin-left: 0.3rem;
	}
	.navbar-toggler-desktop {
		display: none !important;
	}
	.navbar-grid {
		width: 50% !important;
		align-items: start !important;
		top: 52px;
		/* left: 0 !important; */
	}
}

@media screen and (max-width: 920px) {
	.footer-links-header {
		color: black;
		text-decoration: none;
		cursor: pointer;
		font-size: 16px;
	}
	.navigtion_div {
		margin-right: 0rem;
	}
	.login-btn {
		margin-left: 0rem;
	}
	.search_icon {
		margin-right: 1rem;
	}
}
