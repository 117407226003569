input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.selected-values {
	color: white !important;
	width: 200px;
	height: 40px;
}

#free-solo-2-demo-label {
	padding-left: 30px;
	padding-top: 1px;
}
.contact-us-right {
	margin-left: 50%;
	display: flex;
	justify-content: flex-start;
}
.add-seller-cursor {
	cursor: pointer;
}
.contact-us-left {
	width: 58%;
	float: left;
	padding-left: 22%;
}
#free-solo-2-demo {
	margin-left: 40px;
}
.add-new-button-sellers:active {
	background-color: var(--yellow);
	color: var(--black);
}
.add-new-seller-Parent {
	text-align: center;
}
.add-new-button-sellers {
	background-color: var(--yellow);
	color: var(--black);
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 14px !important;
	border-color: #a5a5a5;
}
.add-new-button-sellers:hover {
	background-color: var(--yellow);
	color: var(--black);
}
.inputRounded .MuiOutlinedInput-root {
	border-radius: 80px;
	height: 40px;
}
.newbox {
	display: flex;
}
.fixed {
	width: 250px;
}
.flex-item {
	width: 200px;
	margin-left: 20px;
	flex-grow: 1;
	border-top: white;
}
.flex-item2 {
	width: 200px;
	margin-top: 10px;
	margin-left: 20px;
	flex-grow: 2;
	border: none;
}
.inline-block-child {
	display: inline-block;
}
.MuiSvgIcon-fontSizeSmall:hover {
	background: white;
}
.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
	height: 0.1px;
}
.css-14lo706 {
	font-size: 1rem !important;
}
body,
html {
	font-family: Arial, Helvetica, sans-serif;
}
.MuiInputBase-input {
	height: 0.5rem;
}
.inputRounded .MuiOutlinedInput-root {
	height: 40px;
}
#free-solo-2-demo-label {
	margin-top: -7px;
}

.autocomplete-search-link {
	width: 55rem;
	text-decoration: none;
	color: #000;
}
.autocomplete-search-inputfield {
	position: relative;
	width: 100%;
	display: inline-block;
}
.autocomplete-searchicon {
	position: absolute;
	left: 3px;
	top: 9px;
}
.auto-textfield {
	height: 0px;
}
.textfiled-margin {
	margin-bottom: 4rem;
}
.submit_button {
	background-color: #dcf788;
	width: 7rem;
}
.submit_button:hover {
	background-color: rgb(199, 243, 78);
	width: 7rem;
}
.category {
	margin-bottom: -2rem;
}

.seller-list-Modal .modal-content {
	background-color: #555555;
}
.progressColor {
	color: gray !important;
}
.btn-close {
	color: white;
}
.links {
	color: var(--limegreen);
}
.links:hover {
	color: var(--limegreen);
}
.search-icon {
	color: #525252;
}
.popup-height {
	min-height: 150px;
}
.add-a-seller-button {
	background-color: var(--yellow);
	color: var(--black);
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 14px !important;
	border-color: #a5a5a5;
}
.no-seller {
	display: flex;
	justify-content: center;
	color: white;
	align-items: center;
	height: 160px;
}

@media screen and (max-width: 991px) {
	.textfiled-margin {
		margin-bottom: 0.5rem;
	}
}
@media screen and (max-width: 767px) {
	.textfiled-margin {
		margin-bottom: 3.5rem;
	}
	.popup-height {
		margin-left: 20px;
	}
}
