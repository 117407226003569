.sellersinstructions-wrapper-container {
	margin-bottom: 20px;
}

.seller-ins-sub-headings {
	font-size: 1.5rem;
	font-weight: 600;
}
@media only screen and (max-width: 550px) {
	.sellersinstructions-wrapper-container {
		margin-bottom: 20px;
	}
	.seller-ins-sub-headings {
		font-size: 1rem;
		font-weight: 400;
	}
}
