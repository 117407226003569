.login-form {
	margin-bottom: 7rem;
}
.eye-icon {
	position: absolute;
	right: 20px;
	top: 10px;
	cursor: pointer;
}
.login-container {
	width: 50%;
}
.sign-up-anchor {
	font-size: 18px;
}
.login-form-inputs {
	width: 75%;
}
.loaderCenter {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 100;
	display: flex;
	align-items: center;
}

.login-parents {
	padding: 0px 40px;
}

.login-link {
	text-align: center;
	font-size: 17px;
}
.paragraph {
	font-size: 12px;
	color: blue;
}
.login {
	background-color: rgb(207, 255, 76);
	color: var(--black);
	width: 30%;
	border: none;
	font-size: 16px;
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
	font-weight: bold;
}

.login:hover {
	background-color: rgb(182, 246, 7);
	color: var(--black);
}
.login:active {
	background-color: rgb(182, 246, 7) !important;
	color: var(--black) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.required {
	margin-top: -0.5rem;
}

@media screen and (max-width: 450px) {
	.sign-up-anchor {
		font-size: 17px;
	}
	.login-form {
		margin-bottom: 10rem;
	}
	.login-container {
		width: 100%;
	}
	.login-link {
		font-size: 16px;
		padding-left: 10px;
		padding-right: 10px;
	}
	.login {
		width: 50%;
	}
}

@media screen and (max-width: 600px) {
	.login-parents {
		padding: 0px 10px;
	}
	.login-form-inputs {
		width: 90%;
	}
}

@media screen and (max-width: 768px) {
	.login-container {
		width: 100%;
	}
	.login-parents {
		padding: 0px 10px;
	}
}
