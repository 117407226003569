.privacy-wrapper-container {
	margin-bottom: 20px;
}
.policies-btn {
	background-color: #0dcaf0;
}
.privacy-sub-headings {
	font-size: 1.5rem;
	font-weight: 600;
}
.opinion-btn {
	background-color: #157347;
}
.interest-btn {
	background-color: #ffc107;
}
