:root {
	--limegreen: #dcf788;
	--grey: #555555;
	--lightGrey: #525252;
	--grey-buttons: #727272;
	--black: #000;
	--white: #fff;
	--blue: #3d85c6;
	--yellow: #ffd301;
}

body {
	margin: 0;
	/*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
	/*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue  Fira Sans", sans-serif',*/
	/*  sans-serif;*/
	font-family: "Fira Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}